import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  CardGiftcard,
  LocalAtm,
  Dehaze,
  Groups,
  Person,
} from "@mui/icons-material";
import {
  Box,
  useMediaQuery,
  useTheme,
  AppBar,
  BottomNavigation,
  BottomNavigationAction, Grid, Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { selectCurrentToken } from "../features/slices/authSlice";
import { selectAlertIsOpen } from "../features/slices/alertSlice";

import { useGetCommonBackgroundQuery } from "../features/api/publicApiSlice";

import DesktopHeader from "./Header/DesktopHeader";
import MobileHeader from "./Header/MobileHeader";
import Footer from "./Footer";
import SideNav from "./common/SideNav";
import SnackbarAlert from "./common/SnackbarAlert";
import * as Constant from "../features/constant";
import Drawer from "./common/Drawer";
import { useGetProfileQuery } from "../features/api/userApiSlice";
import SpinWheelSideNavBar from "../components/common/SpinWheelSideNavBar";

const Layout = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const token = useSelector(selectCurrentToken);
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const alertIsOpen = useSelector(selectAlertIsOpen);
  const [showPopup, setShowPopup] = useState(true);

  const {
    data: commonBackground,
    isLoading: isCommonBackgroundLoading,
    isSuccess: isCommonBackgroundSuccess,
  } = useGetCommonBackgroundQuery({ prefetch: true });

  const handleClose = () => {
    setShowPopup(false);
  };

  const triggerLiveChat = () => {
    if (
      typeof window.LiveChatWidget !== "undefined" &&
      window.LiveChatWidget.call
    ) {
      window.LiveChatWidget.call("maximize");
    }
  };

  const {
    data: user,
    isFetching: isUserFetching,
    isLoading: isUserLoading,
  } = useGetProfileQuery({ prefetch: true });

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleButtonClick = () => {
    setIsDrawerOpen(true); // Always open the drawer
  };

  const drawerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        setIsDrawerOpen(false);
      }
    };
  
    if (isDrawerOpen) {
      setTimeout(() => {
        document.addEventListener("click", handleClickOutside);
      }, 100);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
  
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isDrawerOpen]);

  return (

    <Box
      display="flex"
      flexDirection="column"
      backgroundColor="black"
      minHeight="100vh"
    >
      {alertIsOpen && <SnackbarAlert />}
      {<SideNav />}
      {/* {<SpinWheelSideNavBar />} */}
      {/*<div>*/}
      {/*  <Grid container>*/}
      {/*    <Grid item style={{*/}
      {/*      display: showPopup ? 'flex' : 'none',*/}
      {/*      flexDirection: 'row',*/}
      {/*      alignItems: 'center',*/}
      {/*      justifyContent: 'center',*/}
      {/*      backgroundColor: '#ee2842',*/}
      {/*      position: 'fixed',*/}
      {/*      top: 0,*/}
      {/*      left: 0,*/}
      {/*      width: '100%',*/}
      {/*      padding: '3px',*/}
      {/*      zIndex: 9999, // Ensure the popup appears above other content*/}
      {/*      textAlign: 'center',*/}
      {/*    }}>*/}
      {/*      <Typography style={{*/}
      {/*        color: 'white',*/}
      {/*        position: 'relative',*/}
      {/*        zIndex: 1,*/}
      {/*        fontSize: '10px',*/}
      {/*        marginRight: '5px',*/}
      {/*        fontWeight: 'bold',*/}
      {/*      }}>*/}
      {/*        {t("header.desc")}*/}
      {/*      </Typography>*/}
      {/*      <button style={{*/}
      {/*        background: theme.palette.button.secondary.main,*/}
      {/*        color: 'white',*/}
      {/*        width: '80px',*/}
      {/*        height: '24px',*/}
      {/*        bottom: '5px',*/}
      {/*        marginRight: '25px',*/}
      {/*        borderRadius: '5px', // Add border-radius for curved corners*/}
      {/*        borderColor: 'transparent',*/}
      {/*        zIndex: 0,*/}
      {/*        fontSize: '10px',*/}
      {/*        fontWeight: 'bold',*/}
      {/*      }}*/}
      {/*              onClick={handleButtonClick}*/}
      {/*      >*/}
      {/*        {t("header.button")}*/}
      {/*      </button>*/}
      {/*      <button style={{*/}
      {/*        border: 'none',*/}
      {/*        background: 'none',*/}
      {/*        cursor: 'pointer',*/}
      {/*        color: 'white',*/}
      {/*        position: 'absolute',*/}
      {/*        top: '10px',*/}
      {/*        right: '5px',*/}
      {/*        fontWeight: 'bold',*/}
      {/*        fontSize: '12px',*/}
      {/*      }} onClick={handleClose}>X*/}
      {/*      </button>*/}
      {/*    </Grid>*/}
      {/*  </Grid>*/}
      {/*</div>*/}
      <header>
        {mobileView ? (
          <>
            <MobileHeader
              background={
                commonBackground?.mobileBackground[0]?.thumbnailUrl
              }
            />
          </>
        ) : (
          <DesktopHeader
            background={commonBackground?.webBackground[0]?.thumbnailUrl}
          />
        )}
      </header>
      <Box flex={1}>
        <Outlet />
      </Box>
      {!mobileView && (
        <footer>
          <Footer
            background={commonBackground?.webBackground[1]?.thumbnailUrl}
            contactDetails={commonBackground?.result}
          />
        </footer>
      )}

      {mobileView && (
        <AppBar
          position="fixed"
          style={{
            top: "auto",
            bottom: 0,
          }}
        >
          <BottomNavigation
            sx={{
              backgroundColor: '#212529',
            }}
            showLabels
            // value={value}
            onChange={(event, newValue) => {
              if (newValue === 0) {
                setIsDrawerOpen(true);
              } else if (newValue === 1) {
                window.fbq('trackCustom', 'footer_Referral');

                // navigate(Constant.getPath("/affiliate"));
                const affiliateUrl = "https://www.aff.winbagh8.com/";
                window.open(affiliateUrl, "_blank");
              } else if (newValue === 2) {
                if (token) {
                  window.fbq('trackCustom', 'footer_Deposit');

                  navigate(Constant.getPath("/profileWallet"), {
                    state: {
                      screen: "Deposit",
                    },
                  });
                } else {
                  navigate(Constant.getPath("/login"));
                }
              } else if (newValue === 3) {
                if (token) {
                  window.fbq('trackCustom', 'PromoCenter');

                  navigate(Constant.getPath("/promocenter"), {
                    state: {
                      isNotProfileWallet: true,
                    },
                  });
                } else {
                  navigate(Constant.getPath("/promotion"));
                }
              } else if (newValue === 4) {
                if (token) {
                  window.fbq('trackCustom', 'ProfileWallet');

                  navigate(Constant.getPath("/profileWallet"));
                } else {
                  navigate(Constant.getPath("/login"));
                }
              }
            }}
          >
            <BottomNavigationAction
              sx={{
                color: "white", "& .MuiSvgIcon-root": { color: theme.palette.primary.main },
                width: "20%",
                minWidth: "20%",
                maxWidth: "20%"
              }}
              label={t("footer.menu")}
              icon={<Dehaze />}
              onClick={handleButtonClick}
            />
            <BottomNavigationAction
              sx={{
                color: "white", "& .MuiSvgIcon-root": { color: theme.palette.primary.main },
                width: "20%",
                minWidth: "20%",
                maxWidth: "20%"
              }}
              label={t("footer.affiliate")}
              icon={<Groups />}
            />
            <BottomNavigationAction
              sx={{
                color: "white", "& .MuiSvgIcon-root": { color: theme.palette.primary.main },
                width: "20%",
                minWidth: "20%",
                maxWidth: "20%"
              }}
              label={t("footer.deposit")}
              icon={<LocalAtm />}
            />
            <BottomNavigationAction
              sx={{
                color: "white", "& .MuiSvgIcon-root": { color: theme.palette.primary.main },
                width: "20%",
                minWidth: "20%",
                maxWidth: "20%"
              }}
              label={t("footer.promotion")}
              icon={<CardGiftcard />}
            />
            <BottomNavigationAction
              sx={{
                color: "white", "& .MuiSvgIcon-root": { color: theme.palette.primary.main },
                width: "20%",
                minWidth: "20%",
                maxWidth: "20%"
              }}
              label={t("footer.account")}
              icon={<Person />}
            />
          </BottomNavigation>

          {isDrawerOpen && (
            <div ref={drawerRef} className="drawer-container">
              <Drawer user={user?.result} />
            </div>
          )}

        </AppBar>
      )}
    </Box>
  );
};

export default Layout;
